(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/assets/javascripts/router.js') >= 0) return;  svs.modules.push('/modules/oddset/assets/javascripts/router.js');
'use strict';

const {
  NavContextProvider
} = svs.oddset.components.nav_context;
const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const {
  useMediaQuery,
  breakpoints
} = svs.components.lbUtils.useMediaQuery;
const {
  BrowserRouter: Router,
  Route,
  Switch
} = ReactRouterDOM;
const {
  pages: {
    PlayPage,
    MyBetsPage,
    GameGuidePage,
    WidgetPage
  },
  components: {
    nav_menu: {
      OddsetNavMenu
    }
  }
} = svs.oddset;
const {
  MenuTabTypes
} = svs.oddset.components.common.constants;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  ErrorBoundaryFallback
} = svs.oddset.components.error_handling;
const getPageComponent = tabType => {
  switch (tabType) {
    case MenuTabTypes.Play:
      return PlayPage;
    case MenuTabTypes.MyBets:
      return MyBetsPage;
    case MenuTabTypes.GameGuide:
      return GameGuidePage;
    case MenuTabTypes.Widget:
    default:
      return WidgetPage;
  }
};
const OddsetRouter = () => {
  const routes = svs.oddset.data.tabsConfig;
  const playRoute = svs.oddset.data.tabsConfigPlay;
  const isSmallScreen = useMediaQuery(breakpoints.down(1080)); 
  const renderMobileMenu = !isSelfService && isSmallScreen;
  const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
  const classNames = [renderMobileMenu ? 'oddset-bg-layer--mobile' : 'oddset-bg-layer--desktop', isSelfService ? 'oddset-bg-layer--selfservice' : '', isLoggedIn ? 'oddset-bg-layer--logged-in' : ''];
  return (
    React.createElement(Router, {
      basename: svs.core.data.baseUrl,
      forceRefresh: true
    }, React.createElement(NavContextProvider, null, React.createElement(ErrorBoundary, {
      fallback: ErrorBoundaryFallback
    }, React.createElement("div", {
      className: "oddset-bg-layer ".concat(classNames.join(' '))
    }, React.createElement(OddsetNavMenu, {
      activeRoutes: routes,
      playRoute: playRoute,
      renderMobileMenu: renderMobileMenu
    }), React.createElement("div", {
      className: "oddset-content".concat(renderMobileMenu ? ' oddset-content--mobile' : '')
    }, React.createElement(Switch, null, routes.filter(route => !route.isPlayRoute).map(route => React.createElement(Route, {
      key: route.path,
      path: "/".concat(route.path),
      render: () => {
        const Component = getPageComponent(route.tabType);
        return React.createElement(Component, {
          widgetAreaIndex: route.widgetArea
        });
      }
    })), React.createElement(Route, {
      key: "playpage",
      path: [playRoute.path, ...routes.filter(route => route.isPlayRoute).map(route => route.path)],
      render: () => React.createElement(PlayPage, null)
    })))))))
  );
};
setGlobal('svs.oddset.OddsetRouter', OddsetRouter);

 })(window);
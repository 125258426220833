(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/assets/javascripts/index.js') >= 0) return;  svs.modules.push('/modules/oddset/assets/javascripts/index.js');
"use strict";


const {
  ErrorBoundary
} = svs.components.errorBoundary;
const OddsetRouter = svs.oddset.OddsetRouter;
const {
  ErrorBoundaryFallback
} = svs.oddset.components.error_handling;
const {
  isUnderMaintenance
} = svs.oddset.data.maintenanceConfig;
const {
  MaintenanceView
} = svs.oddset.components.maintenance_view;
ReactDOM.render(React.createElement(ErrorBoundary, {
  fallback: ErrorBoundaryFallback
}, isUnderMaintenance ? React.createElement(MaintenanceView, null) : React.createElement(OddsetRouter, null)), document.getElementById('oddset-container'));

 })(window);